
import { Embed } from "@/interfaces/Message";
import GenericEmbed from "./GenericEmbed.vue";
import ImageEmbed from "./ImageEmbed.vue";
import YoutubeEmbed from "./YoutubeEmbed.vue";
import TenorEmbed from "./TenorEmbed.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "EmbedMessage",
  components: { GenericEmbed, ImageEmbed, YoutubeEmbed, TenorEmbed },
  props: {
    embed: {
      type: Object as PropType<Embed>,
      required: true,
    },
  },
  computed: {
    tenorEmbed() {
      return this.embed.type === "tenor";
    },
    youtubeEmbed(): any {
      if (this.embed.site_name !== "YouTube") return false;
      if (this.embed.type !== "video.other") return false;
      const regex =
        /((http(s)?:\/\/)?)(www\.)?((youtube\.com\/)|(youtu.be\/))[\S]+/gm;
      if (!this.embed.url.match(regex)) return false;
      return true;
    },
    imageEmbed(): any {
      if (Object.keys(this.embed).length !== 1) return undefined;
      if (!this.embed.image) return undefined;
      return this.embed.image;
    },
  },
});
