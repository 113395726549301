
import Message from "@/interfaces/Message";
import ReactionTemplate from "./ReactionTemplate.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Reactions",
  components: { ReactionTemplate },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    animate: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    buttons(): any {
      return this.message.buttons;
    },
  },
});
