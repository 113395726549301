
import { useWindowProperties } from "@/utils/windowProperties";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ImageMessageEmbed",
  props: {
    image: {
      type: Object as any,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      failed: false,
    };
  },
  computed: {
    isWindowFocused(): any {
      return useWindowProperties().isFocused;
    },
    pauseGifURL(): any {
      let url = this.imageURL;
      if (!this.isWindowFocused && this.isGif) {
        url += `?type=webp`;
      }
      return url;
    },
    isGif(): any {
      return this.imageURL?.endsWith(".gif");
    },
    imageURL(): any {
      if (this.image.url.startsWith("https://nertivia-cdn.ciach795.com"))
        return this.image.url;

      return (
        process.env.VUE_APP_IMAGE_PROXY_URL + encodeURIComponent(this.image.url)
      );
    },
  },
  methods: {
    showContext(event: any) {
      if (!this.$isElectron) return;
      event.preventDefault();
      PopoutsModule.ShowPopout({
        id: "context",
        component: "ImageContextMenu",
        key: this.imageURL + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          imageUrl: this.imageURL,
        },
      });
    },
    onClick() {
      PopoutsModule.ShowPopout({
        id: "image-preview-popout",
        component: "image-preview-popout",
        data: {
          unsafe_url: this.image.url,
          url: this.imageURL,
        },
      });
    },
  },
});
