
import { useWindowProperties } from "@/utils/windowProperties";
import { PropType } from "vue";
import { defineComponent } from "vue";
interface Tenor {
  url: string;
  width: string;
  height: string;
}
export default defineComponent({
  name: "ImageMessageEmbed",
  props: {
    embed: {
      type: Object as PropType<Tenor>,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      failed: false,
    };
  },
  computed: {
    isWindowFocused(): any {
      return useWindowProperties().isFocused;
    },
  },
  watch: {
    isWindowFocused: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          const videoEl = this.$refs.video as HTMLVideoElement;
          if (this.isWindowFocused) videoEl?.play?.();
          else videoEl?.pause?.();
        });
      },
    },
  },
});
