
import Message, { Button } from "@/interfaces/Message";
import Markup from "@/components/Markup";
import { buttonClick } from "@/services/messagesService";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { MESSAGE_BUTTON_CALLBACK } from "@/ServerEventNames";
export default defineComponent({
  name: "MessageSide",
  components: { Markup },
  props: {
    button: {
      type: Object as PropType<Button>,
      required: true,
    },
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  data() {
    return {
      posting: false,
      timeout: null as number | null,
    };
  },
  unmounted() {
    this.$socket.off(MESSAGE_BUTTON_CALLBACK, this.onCallback);
  },
  methods: {
    click() {
      if (this.posting) return;
      if (!this.message.messageID) return;
      this.posting = true;

      this.$socket.once(MESSAGE_BUTTON_CALLBACK, this.onCallback);
      buttonClick(
        this.message.channelId,
        this.message.messageID,
        this.button.id
      );

      this.timeout = window.setTimeout(() => {
        this.posting = false;
      }, 20000);
    },
    onCallback(data: { id: string; message?: string }) {
      if (data.id !== this.button.id) return;
      this.posting = false;
      this.timeout && clearTimeout(this.timeout);
      if (data.message?.trim()) {
        PopoutsModule.ShowPopout({
          id: "custom-emoji-upload-error",
          component: "generic-popout",
          data: {
            title: this.button.name,
            description: data.message,
          },
        });
      }
    },
  },
});
