
import Message from "@/interfaces/Message";
import ButtonTemplate from "./ButtonTemplate.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "MessageSide",
  components: { ButtonTemplate },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
  },
  computed: {
    buttons(): any {
      return this.message.buttons;
    },
  },
});
