
import { Embed } from "@/interfaces/Message";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "GenericEmbed",
  props: {
    embed: {
      type: Object as PropType<Embed>,
      required: true,
    },
  },
  computed: {
    imageUrl(): any {
      if (!this.embed.image?.url) return undefined;
      return (
        process.env.VUE_APP_IMAGE_PROXY_URL +
        encodeURIComponent(this.embed.image.url)
      );
    },
    title(): any {
      return this.embed.title || this.embed.site_name;
    },
    url(): any {
      const url = this.embed.url;
      if (!url) return "#";
      if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
      }
      return `https://${url}`;
    },
  },
  methods: {
    imageClick() {
      PopoutsModule.ShowPopout({
        id: "image-preview-popout",
        component: "image-preview-popout",
        data: {
          url: this.imageUrl,
          unsafe_url: this.embed?.image?.url,
          dimensions: this.embed.image?.dimensions,
        },
      });
    },
    urlClick(event) {
      event.preventDefault();
      PopoutsModule.ShowPopout({
        id: "html-embed-url-sus",
        component: "OpenLinkConfirm",
        data: { url: this.url },
      });
    },
  },
});
