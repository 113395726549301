
import AvatarImage from "@/components/AvatarImage.vue";
import MessageTemplate from "@/components/chat-area/message/MessageTemplate.vue";
import ActionMessageTemplate from "@/components/chat-area/message/ActionMessageTemplate.vue";
import { PopoutsModule } from "@/store/modules/popouts";
import { deleteMessage } from "@/services/messagesService";
import { MessagesModule } from "@/store/modules/messages";
import CustomButton from "@/components/CustomButton.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ProfilePopout",
  components: {
    AvatarImage,
    MessageTemplate,
    ActionMessageTemplate,
    CustomButton,
  },
  props: {
    data: {
      type: Object as PropType<{
        messageID: string;
        channelId: string;
        callback?: any;
      }>,
      required: true,
    },
  },
  computed: {
    message(): any {
      return MessagesModule.channelMessages(this.data.channelId)?.find(
        (m) => m.messageID === this.data.messageID
      );
    },
    messageType(): any {
      return this.message?.type === 0
        ? "MessageTemplate"
        : "ActionMessageTemplate";
    },
    user(): any {
      if (!this.message) return undefined;
      return this.message.creator;
    },
  },
  watch: {
    message: {
      handler: "onMessageChange",
    },
  },
  methods: {
    close() {
      PopoutsModule.ClosePopout("delete-message");
    },
    backgroundClick(event: any) {
      if (event.target.classList.contains("popout-background")) {
        this.close();
      }
    },
    async deleteMessage() {
      if (!this.message) return;
      if (!this.message.messageID) return;
      this.close();
      await deleteMessage(this.message.channelId, this.message.messageID);
      this.data?.callback?.();
    },
    onMessageChange() {
      if (!this.message) {
        this.close();
      }
    },
  },
});
