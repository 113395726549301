
// TODO: remove resizeKeepAspect
import resizeKeepAspect from "@/utils/resizeKeepAspect";
import { useWindowProperties } from "@/utils/windowProperties";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "VideoPlayer",
  components: {},
  props: {
    thumbnailURL: {
      type: String,
      required: true,
    },
    file: {
      type: Object as PropType<{
        fileID: string;
        fileName: string;
        url: string;
      }>,
      required: false,
    },
    youtubeURL: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      loadPlayer: false,
    };
  },
  computed: {
    fileURL(): any {
      if (!this.file) return undefined;
      // nertivia cdn
      if (this.file.url) return this.file.url;
      // google drive cdn
      return (
        process.env.VUE_APP_FETCH_PREFIX +
        `/files/${this.file.fileID}/${this.file.fileName}`
      );
    },
    embedURL(): any {
      if (!this.youtubeURL) return undefined;
      const regex =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*)(?:(\?t|&start)=(\d+))?.*/gim;
      const result = regex.exec(this.youtubeURL);
      if (!result) return undefined;
      const id = result[2];
      const time = parseInt(result[4]);
      let url = `https://www.youtube-nocookie.com/embed/${id}?autoplay=1&vq=hd360`;
      if (!isNaN(time)) {
        url += "&start=" + time;
      }
      return url;
    },
    windowSize(): any {
      const { resizeWidth, resizeHeight } = useWindowProperties();
      return {
        height: resizeHeight,
        width: resizeWidth,
      };
    },
  },
  watch: {
    windowSize: {
      handler: "setDimensions",
    },
  },
  mounted() {
    this.setDimensions();
  },
  methods: {
    setDimensions() {
      const contentEl = this.$refs["content"] as any;
      const logsEl = document.getElementById("messageLogs");

      if (!contentEl) return;
      if (!logsEl) return;
      resizeKeepAspect(contentEl, logsEl, 1920, 1080);
    },
  },
});
