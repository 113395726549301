
import { useWindowProperties } from "@/utils/windowProperties";
import Message from "@/interfaces/Message";
import { PopoutsModule } from "@/store/modules/popouts";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ImageMessageEmbed",
  props: {
    message: {
      type: Object as PropType<Message & { grouped: boolean }>,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    isWindowFocused(): any {
      return useWindowProperties().isFocused;
    },
    pauseGifURL(): any {
      let url = this.imageURL;
      if (!this.isWindowFocused && this.isGif) {
        url += `?type=webp`;
      }
      return url;
    },
    isGif(): any {
      return this.imageURL?.endsWith(".gif");
    },
    imageURL(): any {
      const file = this.message.files?.[0];
      if (!file) return undefined;
      // nertivia cdn
      console.log(file);
      if (file.url) return file.url;
      // google drive cdn
      return (
        process.env.VUE_APP_FETCH_PREFIX +
        `/media/${file.fileID}/${file.fileName}`
      );
    },
    dimensions(): any {
      return this.message.files?.[0]?.dimensions;
    },
  },
  methods: {
    showContext(event: any) {
      if (!this.$isElectron) return;
      event.preventDefault();
      PopoutsModule.ShowPopout({
        id: "context",
        component: "ImageContextMenu",
        key: this.imageURL + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          imageUrl: this.imageURL,
        },
      });
    },
    onClick() {
      PopoutsModule.ShowPopout({
        id: "image-preview-popout",
        component: "image-preview-popout",
        data: {
          url: this.imageURL,
          unsafe_url: this.message.files?.[0].url,
        },
      });
    },
  },
});
