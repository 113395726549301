
import Message from "@/interfaces/Message";
import ImageMessageEmbed from "./ImageMessageEmbed.vue";
import FileMessage from "./FileMessage.vue";
import InviteMessage from "./InviteMessage.vue";
import { MeModule } from "@/store/modules/me";
import friendlyDate from "@/utils/date";
import { ServerMembersModule } from "@/store/modules/serverMembers";
import { PopoutsModule } from "@/store/modules/popouts";
import Markup from "@/components/Markup";
import VideoPlayer from "./VideoPlayer.vue";
import Invite from "@/interfaces/Invite";
import { PropType } from "vue";
import { defineComponent } from "vue";
import HTMLEmbed from "./HTMLEmbed.vue";
export default defineComponent({
  name: "Bubble",
  components: {
    ImageMessageEmbed,
    FileMessage,
    InviteMessage,
    Markup,
    VideoPlayer,
    HTMLEmbed,
  },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    grouped: {
      type: Boolean,
      required: true,
    },
    invite: {
      type: Object as PropType<Invite>,
      required: false,
    },
  },
  data() {
    return {
      loadRoleColor: false,
    };
  },
  computed: {
    creator(): any {
      return this.message.creator;
    },
    isMessageCreatedByMe(): any {
      return this.message.creator.id === MeModule.user.id;
    },
    isFileImage(): any {
      if (!this.file) return false;
      if (!['webp', 'gif', 'png', 'jpeg', 'jpg'].includes(this.file.url.split('.').at(-1))) return false;
      return true;
    },
    isVideo(): any {
      if (!this.file) return false;
      return this.file.fileName?.endsWith(".mp4");
    },
    file(): any {
      const files = this.message.files;
      if (!files || !files.length) return undefined;
      return files[0];
    },
    date(): any {
      return friendlyDate(this.message.created, localStorage["timeFormat"]);
    },
    roleColor(): any {
      if (!this.loadRoleColor) return undefined;
      if (!this.server_id) return undefined;
      const role = ServerMembersModule.firstMemberRole(
        this.server_id,
        this.creator.id
      );
      return role?.color;
    },
    server_id(): any {
      return this.$route.params.server_id;
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.loadRoleColor = true;
    }, 10);
  },
  methods: {
    showProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.creator.id },
      });
    },
    userContext(event: MouseEvent) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "UserContextMenu",
        key: this.message.creator.id + event.clientX + event.clientY,
        data: {
          tempUser: this.message.creator,
          x: event.clientX,
          y: event.clientY,
          id: this.message.creator.id,
          element: event.target,
        },
      });
    },
  },
});
