
import { Embed } from "@/interfaces/Message";
import VideoPlayer from "./VideoPlayer.vue";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "YoutubeEmbed",
  components: { VideoPlayer },
  props: {
    embed: {
      type: Object as PropType<Embed>,
      required: true,
    },
  },
  computed: {
    imageURL(): any {
      if (!this.embed.image?.url) return undefined;
      return (
        process.env.VUE_APP_IMAGE_PROXY_URL +
        encodeURIComponent(this.embed.image.url)
      );
    },
    title(): any {
      return this.embed.title || this.embed.site_name;
    },
    url(): any {
      const url = this.embed.url;
      if (!url) return "#";
      if (url.startsWith("http://") || url.startsWith("https://")) {
        return url;
      }
      return `https://${url}`;
    },
  },
});
