
import Message, { UserExtra } from "@/interfaces/Message";
import AvatarImage from "@/components/AvatarImage.vue";
import Bubble from "./Bubble.vue";
import MessageSide from "./MessageSide.vue";
import ButtonsMessage from "./ButtonsMessage.vue";
import Reactions from "./Reactions.vue";
import EmbedMessage from "./EmbedMessage.vue";
import { time } from "@/utils/date";
import { PopoutsModule } from "@/store/modules/popouts";
import HTMLEmbed from "./HTMLEmbed.vue";
import { UsersModule } from "@/store/modules/users";
import { PropType } from "vue";
import User from "@/interfaces/User";
import { defineComponent } from "vue";
import CheckBox from "@/components/CheckBox.vue";
import { MessagesModule } from "@/store/modules/messages";
import { MeModule } from "@/store/modules/me";
import { permissions } from "@/constants/rolePermissions";
import { ServerMembersModule } from "@/store/modules/serverMembers";
import { ServersModule } from "@/store/modules/servers";
export default defineComponent({
  name: "MessageLogs",
  components: {
    AvatarImage,
    Bubble,
    MessageSide,
    EmbedMessage,
    HTMLEmbed,
    ButtonsMessage,
    Reactions,
    CheckBox,
  },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true,
    },
    grouped: {
      type: Boolean,
      required: true,
    },
    hideContext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contextPos: {} as { x?: number; y?: number },
      hover: false,
      viewBlockedMessage: false,
    };
  },
  computed: {
    canDeleteMessage(): any {
      if (!this.message.messageID) return false;
      if (this.message.localMessage) return false;
      if (this.messageCreatedByMe) return true;
      if (!this.serverID) return false;
      if (this.isServerOwner) return true;
      if (!MeModule.user.id) return false;
      return ServerMembersModule.memberHasPermission(
        MeModule.user.id,
        this.serverID,
        permissions.ADMIN.value
      );
    },
    messageCreatedByMe(): any {
      return MeModule.user.id === this.message.creator.id;
    },
    isServerOwner(): any {
      if (!this.serverID) return false;
      const server = ServersModule.servers[this.serverID];
      return MeModule.user.id === server.creator.id;
    },
    serverID(): any {
      if (this.currentTab !== "servers") return undefined;
      return this.$route.params.server_id;
    },
    currentTab(): any {
      return this.$route.path.split("/")[2];
    },
    showCheckbox() {
      return MessagesModule.selectedMessageIds.length;
    },
    isSelected() {
      if (!this.message.messageID) return false;
      return MessagesModule.isMessageSelected(this.message.messageID);
    },
    invite(): any {
      const inviteLinkRegex = new RegExp(
        `${process.env.VUE_APP_MAIN_APP_URL}(invites|i)/([\\S]+)`
      );
      if (!this.message.message) return null;
      return this.message.message.match(inviteLinkRegex);
    },
    creator(): User & UserExtra {
      return this.message.creator;
    },
    friendlyTime(): any {
      return time(this.message.created, localStorage["timeFormat"]);
    },
    embed(): any {
      if (!this.message.embed) return undefined;
      if (!Object.keys(this.message.embed).length) return undefined;
      return this.message.embed;
    },
    isBlocked(): any {
      return (
        !this.viewBlockedMessage &&
        UsersModule.blockedUserIDArr.includes(this.message.creator.id)
      );
    },
  },
  methods: {
    selectMessage() {
      if (!this.message.messageID) return;
      if (!this.canDeleteMessage && !this.isSelected) return;
      if (this.isSelected) {
        MessagesModule.unselectMessage(this.message.messageID);
        return;
      }
      MessagesModule.selectMessage(this.message.messageID);
    },
    onDoubleClick(event: MouseEvent & { target: HTMLElement }) {
      const whitelistArr = [".date", ".time"];
      const closest = this.elementClosestInArray(event.target, whitelistArr);
      if (!closest) return;
      this.selectMessage();
    },
    onCheckBoxClick() {
      this.selectMessage();
    },
    showProfile() {
      PopoutsModule.ShowPopout({
        id: "profile",
        component: "profile-popout",
        data: { id: this.creator.id },
      });
    },
    messageContext(event: MouseEvent & { target: HTMLElement }) {
      if (this.$isMobile) return;
      const whitelistArr = [".message-content", ".date", ".time"];
      const closest = this.elementClosestInArray(event.target, whitelistArr);
      if (!closest) return;
      event.preventDefault();
      const id = this.message.tempID || this.message.messageID || "";
      PopoutsModule.ShowPopout({
        id: "context",
        component: "MessageContextMenu",
        key: id + event.pageX + event.pageY,
        data: {
          x: event.pageX,
          y: event.pageY,
          message: this.message,
          tempUser: this.message.creator,
          element: event.target,
        },
      });
    },
    elementClosestInArray(element: HTMLElement, targets: string[]) {
      for (let index = 0; index < targets.length; index++) {
        const allowClass = targets[index];
        const closestElement = element.closest(allowClass);
        if (closestElement) {
          return closestElement;
        }
      }
      return false;
    },
    userContext(event: MouseEvent) {
      PopoutsModule.ShowPopout({
        id: "context",
        component: "UserContextMenu",
        key: this.message.creator.id + event.clientX + event.clientY,
        data: {
          x: event.clientX,
          y: event.clientY,
          tempUser: this.message.creator,
          id: this.message.creator.id,
        },
      });
    },
  },
});
