
import Message from "@/interfaces/Message";
import { PropType } from "vue";
import { defineComponent } from "vue";
export default defineComponent({
  name: "ImageMessageEmbed",
  props: {
    message: {
      type: Object as PropType<Message & { grouped: boolean }>,
      required: true,
    },
  },
  computed: {
    file(): any {
      return this.message.files?.[0];
    },
    url(): any {
      if (this.file.url) return this.file.url;
      return `${process.env.VUE_APP_FETCH_PREFIX}/files/${this.file?.fileID}/${this.file?.fileName}`;
    },
  },
});
