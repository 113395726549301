
import {
  getServerInfoByCode,
  joinServerByCode,
} from "@/services/serverService";
import AvatarImage from "@/components/AvatarImage.vue";
import CustomButton from "@/components/CustomButton.vue";
import LoadingScreen from "@/components/LoadingScreen.vue";
import { ServersModule } from "@/store/modules/servers";
import { PropType } from "vue";
import { defineComponent } from "vue";
import { PopoutsModule } from "@/store/modules/popouts";
export default defineComponent({
  name: "InviteMessage",
  components: { AvatarImage, CustomButton, LoadingScreen },
  props: {
    invite: {
      type: Object as any,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      requestSent: false,
      invalid: false,
      result: null as any,
    };
  },
  computed: {
    bannerURL(): any {
      if (!this.result?.banner) return null;
      return (
        process.env.VUE_APP_NERTIVIA_CDN + this.result.banner + "?type=webp"
      );
    },
    isJoined(): any {
      return ServersModule.servers[this.result.server_id];
    },
  },
  mounted() {
    const code = this.invite[2];
    getServerInfoByCode(code)
      .then((res) => {
        this.result = res;
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
        this.invalid = true;
      });
  },
  methods: {
    visitServer() {
      this.$router.push(
        `/app/servers/${this.result.server_id}/${this.result.default_channel_id}`
      );
    },
    joinServer() {
      PopoutsModule.ShowPopout({
        id: "captcha-popout",
        component: "CaptchaPopout",
        data: {
          callback: this.captchaVerified,
        },
      });
    },
    captchaVerified(token: string) {
      if (this.requestSent) return;
      this.requestSent = true;
      const code = this.invite[2];
      joinServerByCode(code, { socketID: this.$socket.id, token });
    },
  },
});
